<template>
    <v-main locales="en">
      <div>
        <router-view/>
      </div>
      <v-footer v-if="releaseDataList.length > 0 ">
        <span @click="showChangeLog"> {{ this.$t('relaseNote.lastUpdate') }} {{new Date(this.releaseDataList[0].released_at).toISOString().slice(0, 10)}} | {{ this.$t('relaseNote.copyRight') }} &copy;  TLIC CMU</span>
        <v-spacer></v-spacer>
        <ModalReleaseNote :showModal="showReleaseNote" :dataList="releaseDataList" @onClose="onClose"></ModalReleaseNote>
      </v-footer>  
    </v-main>
</template>
<script>
// import $i18n from "main.js"
import apiGitlabService from "@/services/apiGitlabService"
import ModalReleaseNote from "@/components/ModalNotification/ModalReleaseNote"

export default {
  name: 'App',
  components: {
    ModalReleaseNote
  },
  data: () => ({
      user: {
        initials: 'JD',
        fullName: 'John Doe',
        email: 'john.doe@doe.com',
      },
      showReleaseNote : false,
      releaseDataList : {} 
    //
  }),
  watch:{
      showReleaseNote(val , oldval){
        // this.syncStatus = !val
        console.log("toggle :>" , val ,oldval );        
      },
  },
  created(){
    this.$i18n.set('en')
    this.getRelease()
  },
  methods:{
    async getRelease(){
      try {
        const response = await apiGitlabService.getRelease()
        delete response.status
        this.releaseDataList = response
        console.log("getRelease :>" , this.releaseDataList)
      } catch (error) {
        console.log("getRelease faild")
      }
     
    },
    async showChangeLog(){
      const response = await apiGitlabService.getRelease()
      // this.$i18n.locale() == "en" ? this.$i18n.set('th') : this.$i18n.set('en');
      delete response.status
      this.releaseDataList = response
      this.showReleaseNote = true

    },
    onClose(){
      this.showReleaseNote = false
    }
    // async getRelease(){
    //   const response = await apiGitlabService.getRelease()
    //   console.log("getRelease :>" , response)
    // },
  }
};
</script>